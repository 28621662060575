<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'w-full' }"
    :popper="{
      placement: 'bottom-start',
      strategy: 'absolute',
    }"
  >
    <template #panel>
      <div class="px-3 py-2">
        <UInput
          v-model="search"
          icon="i-heroicons-magnifying-glass"
          placeholder="Search field"
          autofocus
        />
      </div>
      <div
        class="max-h-96 scroll-stable overflow-y-auto minimal-scrollbar"
      >
        <div class="px-3 py-2 ">
          <p class="text-xs text-gray-500 font-semibold">All field types</p>
          <div class="space-y-2 mt-2">
            <div
              v-for="field in filteredFields"
              :key="field.type"
              class="flex items-center gap-2 cursor-pointer rounded hover:bg-gray-50 p-1"
              data-test="field-select-item"
              @click="onSelectField(field.type)"
            >
              <FieldIcon :type="field.type" />
              <span class="text-sm font-gray-900">{{ field.label }}</span>
            </div>
          </div>
        </div>
        <template v-if="showSuggested && suggestedFields?.length">
          <UDivider />
          <div class="px-3 py-2">
            <p class="text-xs text-gray-500 font-semibold">Custom fields have been created</p>
            <div class="space-y-2 mt-2">
              <div
                v-for="field in suggestedFields"
                :key="field.id"
                class="flex justify-between items-center h-8 gap-2 cursor-pointer rounded hover:bg-gray-50 p-1 group/custom-field"
                @click="onSelectSuggestedField(field)"
              >
                <div class="flex items-center gap-2">
                  <FieldIcon :type="field.type" />
                  <span class="text-sm font-gray-900">{{ field.name }}</span>
                </div>
                <UButton
                  variant="ghost"
                  color="gray"
                  icon="i-heroicons-trash-solid"
                  class="text-gray-700 hidden group-hover/custom-field:flex"
                  @click.prevent.stop="emit('remove', field)"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <slot />
  </UPopover>
</template>

<script lang="ts" setup>
import { FieldLists, type FieldType } from '#field/constant'
import type { Field } from '#field/types'

const props = defineProps({
  ignoredFields: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  showSuggested: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits<{
  select: [type: FieldType],
  attach: [field: Field],
  remove: [field: Field],
}>()

const open = ref(false)
const search = ref('')

const { currentWorkspace } = useWorkspaceSharedState()
const variables = reactive({
  workspaceId: currentWorkspace.value.id,
  filter: {
    search: '',
  }
})

const { result, load } = useWorkspaceFieldsLazyQuery(variables)

const suggestedFields = computed(() => {
  const searchTerm = search.value.toLowerCase()
  return result.value?.workspaceFields.filter(
    (field) => !props.ignoredFields.some((item) => item === field.id) && field?.name.toLowerCase().includes(searchTerm)
  )
})

const filteredFields = computed(() =>
  FieldLists.filter((field) =>
    field.label.toLowerCase().includes(search.value.toLowerCase())
  )
)

const onSelectField = (type: FieldType) => {
  emit('select', type)
  open.value = false
}

const onSelectSuggestedField = (field: Field) => {
  emit('attach', field)
  open.value = false
}

watch(
  () => [open.value, props.showSuggested],
  ([open, showSuggested]) => {
    if (open && showSuggested) {
      load()
    }
  }
)
</script>
